import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
//import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'; // Ensure Vuetify's styles are loaded
import VueMeta from 'vue-meta';
import '@mdi/font/css/materialdesignicons.css';

//Vue.use(Vuetify);

Vue.config.productionTip = false;
Vue.use(VueMeta);

new Vue({
  vuetify,
  //vuetify: new Vuetify(), // Add this line to specify Vuetify as a plugin
  render: h => h(App),
}).$mount('#app');
