<template>
  <v-app>
    <!-- Navbar Section -->
    <v-app-bar app :style="{ backgroundColor: '#E0DFDB' }">
      <!-- Logo -->
      <v-toolbar-title>
        <v-img
          src="@/assets/logo.png"
          contain
          max-height="40"
          max-width="120"
        ></v-img>
      </v-toolbar-title>

      <!-- Text "Fare & Balanced" next to the logo -->
      <div class="navbar-text">Fare & Balanced</div>

      <v-spacer></v-spacer>

      <!-- Join Waitlist Button -->
      <v-btn text @click="openDialog">Join The Waitlist</v-btn>
    </v-app-bar>

    <!-- Hero Section -->
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-img
            :src="require('@/assets/Images/realistic_cows_grazing_with_drone.png')"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            class="white--text"
            height="600px"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12" class="text-center">
                <div class="display-1" style="font-family: Montserrat, sans-serif;">Welcome to a New Dawn in Investment: Fare and Balanced</div>
                <div class="title" style="font-family: Lora, serif;">Investing with Heart, Nourishing the Future</div>
                <div class="body-1" style="font-family: Lora, serif;">Imagine a platform where investing in farm shares is as simple and impactful as trading stocks—this is Fare and Balanced. Here, every investment flourishes into nourishment for you, and a leap towards health and equality for communities around the globe.</div>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="my-5">
      <v-row class="fill-height">
        <!-- Our Mission Column -->
        <v-col cols="12" md="6" class="d-flex">
          <v-card elevation="2" class="pa-3 flex-fill">
            <v-card-title class="text-h5" style="font-family: Montserrat, sans-serif;">Our Mission</v-card-title>
            <v-card-text>
              <ul class="custom-list">
                <li>
                  <h3>Empowerment Through Investment</h3>
                  <p>Dive into the world of sustainable food sources. With each share you buy, you not only secure a stake in wholesome, sustainable farming but also extend a share to a family in need. It’s your chance to contribute to a world where everyone has access to healthy, sustainably grown food.</p>
                </li>
                <li>
                  <h3>Sustainability at Your Fingertips</h3>
                  <p>Our intuitive app/website makes supporting regenerative agriculture as easy as a swipe or click. Invest in the earth, reap fresh, whole foods, and witness the tangible impact of your contribution.</p>
                </li>
                <li>
                  <h3>Join the Circle of Impact</h3>
                  <p>By joining Fare and Balanced, you become part of a movement. A movement that feeds families, nurtures the planet, and builds a community of health-conscious consumers, sustainability advocates, and impact investors just like you.</p>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Why Fare and Balanced Column -->
        <v-col cols="12" md="6" class="d-flex">
          <v-card elevation="2" class="pa-3 flex-fill">
            <v-card-title class="text-h5" style="font-family: Montserrat, sans-serif;">Why Fare and Balanced?</v-card-title>
            <v-card-text>
              <ul class="custom-list">
                <li>
                  <h3>For Health and Wellness</h3>
                  <p>Your investment brings farm-fresh, whole foods directly to your table and to the homes of underserved families.</p>
                </li>
                <li>
                  <h3>For a Greener Tomorrow</h3>
                  <p>Align your investments with your values. Support sustainable, regenerative farming practices that heal the planet.</p>
                </li>
                <li>
                  <h3>For the Joy of Giving</h3>
                  <p>Experience the unparalleled satisfaction of knowing that with every share you buy, a share is given to nourish those in need.</p>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>


    <!-- Features Section -->
    <v-container class="my-5">
    <v-row class="fill-height">

      <!-- Your Path to Impactful Investing -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card class="pa-3 flex-fill" elevation="2">
          <v-img
            :src="require('@/assets/Images/harmonious_scene_of_farm.png')"
            gradient="to bottom, rgba(0,0,0,.7), rgba(0,0,0,.7)"
            class="white--text align-end"
            height="300px"
          >
            <v-card-title class="text-h5 empowering-communities-title" style="font-family: Montserrat, sans-serif;">
              Your Path Forward
            </v-card-title>
            <v-card-text>
              <ul>
                <li><strong>Join the Waitlist:</strong> Sign up today and stay ahead with updates on our platform’s launch.</li>
                <li><strong>Discover:</strong> Explore farms that share your commitment to health and sustainability.</li>
                <li><strong>Harvest the Benefits:</strong> Watch your investments grow and feel the joy of making a real difference.</li>
              </ul>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>

      <!-- Empowering Communities, One Share at a Time -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card class="pa-3 flex-fill" elevation="2">
          <v-img
            :src="require('@/assets/Images/indoor_setting_where_a_family_gathers_around.png')"
            gradient="to bottom, rgba(0,0,0,.7), rgba(0,0,0,.7)"
            class="white--text align-end"
            height="300px"
          >
            <v-card-title class="text-h5 empowering-communities-title" style="font-family: Montserrat, sans-serif;">
              Empowering Communities
            </v-card-title>
            <v-card-text>
              At Fare and Balanced, we believe that the path to a healthier, more sustainable world is paved with shared success and communal support. That’s why we’re excited to introduce our "Get a Share, Give a Share" program — an initiative designed to extend the benefits of sustainable farming to everyone, regardless of their economic background.
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>

      <!-- Be the Change with Every Investment -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card class="pa-3 flex-fill" elevation="2">
          <v-img
            :src="require('@/assets/Images/greenhouse_filled.png')"
            gradient="to bottom, rgba(0,0,0,.7), rgba(0,0,0,.7)"
            class="white--text align-end"
            height="300px"
          >
            <v-card-title class="empowering-communities-title">The Truest Investment</v-card-title>
            <v-card-text>
              Fare and Balanced invites you to redefine what it means to invest. It’s not just about financial returns; it’s about making a real, lasting difference in the world. It’s about planting seeds of change with every investment and watching as those seeds grow into a healthier, more sustainable future for all.
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

    <!-- Call to Action Section -->
    <v-row justify="center">
      <v-col cols="12" class="text-center my-5">
        <v-btn color="#45B649" dark large @click="dialog = true">
          Join The Waitlist Now
        </v-btn>
      </v-col>
    </v-row>

    <!-- Email Dialog -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-img
            :src="require('@/assets/Images/A_serene_dawn_breaks_over_a_farm_with_the_sky.png')"
            class="dialog-background"
            gradient="to top, rgba(0,0,0,.5), rgba(0,0,0,.5)"
          >
          <v-card-title class="text-h5 title-white">Join the Waitlist</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
              <label for="question1" class="questions-text">What excites you most about our sustainable farm sharing, including both plant and animal farming?</label>
              <textarea id="question1" v-model="question1" rows="1" required class="textarea-white"></textarea>

              <label for="question2" class="questions-text">How much money per share would you invest in sustainable farm sharing?</label>
              <textarea id="question2" v-model="question2" rows="1" required class="textarea-white"></textarea>

              <label for="question3" class="questions-text">What challenges do you hope our farm sharing service will solve for you?</label>
              <textarea id="question3" v-model="question3" rows="1" required class="textarea-white"></textarea>

              <label for="question4" class="questions-text">How did you discover our farm sharing platform?</label>
              <textarea id="question4" v-model="question4" rows="1" required class="textarea-white"></textarea>

              <label for="email" class="questions-text">Email</label>
              <input type="email" id="email" v-model="email" required class="textarea-white">
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="closeDialog">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="onSubmit">Submit</v-btn>
          </v-card-actions>
        </v-img>
      </v-card>
    </v-dialog>

    <!-- Popup -->
    <v-dialog v-model="showInitialPopup" persistent max-width="800px">
      <v-card class="elevation-6" style="border: 4px solid white; display: flex; flex-direction: column; justify-content: space-between;">
        <v-img
            :src="require('@/assets/Images/Photorealistic_image_capturing_the_first_light.png')"
            class="dialog-background"
            gradient="to top, rgba(0,0,0,.5), rgba(0,0,0,.5)"
            aspect-ratio="1.5"
        >
          <v-container>
            <v-card-title class="text-h5" style="color: white; font-size: 24px;">Take Our Survey</v-card-title>
            <v-card-text style="color: white; font-size: 18px;">
              <p>Take our one minute survey on how we can better serve you and tell us what you expect from our service. Your feedback is valuable to us.</p>
            </v-card-text>
          </v-container>
        </v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="openSurvey">Take me to it!</v-btn>
          <v-btn color="grey darken-1" text @click="closePopup">Proceed to site</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Footer -->
    <v-footer color="innovation-gray" padless>
      <v-row class="px-5 py-3" align="center" justify="space-between">
        
        <!-- Left side: Copyright Text -->
        <v-col cols="12" sm="6" class="black--text" style="font-family: Lora, serif;">
          Fare and Balanced &copy; 2024 All Rights Reserved
        </v-col>
        
        <!-- Right side: Social Links -->
        <v-col cols="12" sm="6" class="d-flex justify-end align-center">
          <span class="mr-2 black--text" style="font-family: Lora, serif;">Connect with us:</span>
          <!-- Instagram Link -->
          <v-btn icon href="https://www.instagram.com/fareandbalanced?igshid=ujo3wmd4d7b2x" target="_blank" rel="noopener noreferrer">
            <v-icon size="24px" color="black">mdi-instagram</v-icon>
          </v-btn>
          <!-- LinkedIn Link -->
          <v-btn icon href="https://www.linkedin.com/company/fare-and-balanced/" target="_blank" rel="noopener noreferrer">
            <v-icon size="24px" color="black">mdi-linkedin</v-icon>
          </v-btn>
        </v-col>
        
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
// Import the Firestore database instance and necessary functions
import { db } from '@/firebaseConfig';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

export default {
  name: 'App',
  metaInfo: {
    title: 'Fare and Balanced - Sustainable Food Trading App',
    meta: [
      {
        name: 'description',
        content: 'Join Fare and Balanced, the leading Sustainable Food Trading App, for eco-conscious food investments. Enjoy healthy eating with our organic food sharing platform. Every share supports a low-income family. #OrganicFoodAccessEquity'
      },
      {
        name: 'keywords',
        content: 'Healthy Eating App, Regenerative Agriculture Shares, Organic Food Sharing Platform, Eco-Conscious Food Investments, Farm Share for Low-Income Families, Sustainable Food Trading App, Organic Food Access Equity, Digital Farmer\'s Market, Health and Wellness Food Platform, Impact Investing in Agriculture, Regenerative Farming Community, Social Impact Food Sharing, Sustainable Living Advocates, Family Health Organic Food'
      }
    ]
  },
  data() {
    return {
      showInitialPopup: false, // Controls the visibility of the initial popup
      dialog: false, // Controls the visibility of the dialog
      email: '', // Stores the user's email input
      question1: '',
      question2: '',
      question3: '',
      question4: '',
      valid: true, // Tracks the validity of the form
      // Defines the rules for validating the email input
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  mounted() {
    // Show the dialog after 1000 milliseconds (1 second)
    setTimeout(() => {
      this.showInitialPopup = true;
    }, 1000);
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.email = '';
      this.question1 = '';
      this.question2 = '';
      this.question3 = '';
      this.question4 = '';
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    openSurvey(){
      this.showInitialPopup = false;
      this.openDialog();
    },
    closePopup(){
      this.dialog = false;
      this.showInitialPopup = false;
    },
    async saveResponse(formData) {
      try {
        await addDoc(collection(db, 'landingPageInfo'), {
          ...formData,
          timestamp: serverTimestamp(),
        });
        console.log('Response saved successfully');
        this.closeDialog(); // Assuming closeDialog is a method that closes your form dialog
      } catch (error) {
        console.error('Error saving response: ', error);
      }
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        const submission = {
          email: this.email,
          whatExcitesYou: this.question1,
          howMuchMoney: this.question2,
          whatChallenges: this.question3,
          howDidYouDiscover: this.question4,
        };
        console.log("Email submitted:", this.email);
        
        // Save the form data to Firestore
        this.saveResponse(submission);
      }
    }
  },
};
</script>


<style>
/* Montserrat and Lora fonts import */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&family=Lora:wght@400;400i&display=swap');

/* Color palette */
:root {
  --optimistic-sky-blue: #89CFF0;
  --rich-earth-brown: #654321;
  --vibrant-green: #45B649;
  --sunset-gold: #FDB927;
  --innovation-gray: #56595D;
}

/* Typography */
body {
  font-family: 'Lora', serif;
}

/* Custom classes */
.innovation-gray {
  background-color: var(--innovation-gray);
}

.cta-button {
  background-color: var(--vibrant-green) !important; /* Use your vibrant green for the CTA button */
  color: white !important;
}

.dialog-content {
  font-family: 'Montserrat', sans-serif; /* Use Montserrat for the dialog content */
}

/* Dialog Button Styles */
.dialog-button {
  color: var(--rich-earth-brown) !important; /* Use your rich earth brown for dialog buttons */
}

/* Input field style */
.email-input {
  color: var(--innovation-gray) !important; /* Use your innovation gray for input text */
}

.empowering-communities-title {
  white-space: pre-wrap;
  word-break: keep-all;
}

.text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.custom-list {
  list-style: none; /* Removes default list styling */
  padding-left: 0; /* Removes default padding */
}

.custom-list li h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.custom-list li p {
  font-family: 'Lora', serif;
  font-size: clamp(0.9rem, 1vw, 1rem); /* Responsive font size */
  margin: 0;
}

@media (max-width: 600px) {
  .v-card {
    padding: 10px; /* Further reduce padding for very small screens */
  }

  .v-dialog {
    max-width: 95% !important; /* Make dialog responsive on small devices */
  }
}

/* Ensuring text does not awkwardly break */
.custom-list li p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

textarea, input[type="email"] {
  width: 100%; /* Ensure input fields do not overflow their container */
  font-family: 'Lora', serif; /* Applying the Lora font to match body text */
  margin-bottom: 1rem; /* Space out each form element */
  border: 1px solid #ccc; /* Basic styling for inputs */
  padding: 0.5rem;
}

label {
  font-family: 'Montserrat', sans-serif;
  display: block; /* Ensure labels are on their own line */
  margin-bottom: 0.5rem;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.btn-cancel, .btn-submit {
  font-family: 'Montserrat', sans-serif;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.btn-cancel {
  background-color: var(--rich-earth-brown);
  color: white;
  margin-right: 0.5rem;
}

.btn-submit {
  background-color: var(--vibrant-green);
  color: white;
}

.textarea-white {
  background-color: white;
  border: 1px solid #ccc; /* You can adjust the border color as needed */
  width: 100%; /* Ensures the textarea fully occupies its container */
  padding: 8px; /* Adds some padding inside the textarea for better text readability */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  font-family: inherit; /* Inherits the font from the body or specified parent element */
}

.questions-text{
  color: white;
}

.title-white {
  color: white !important; /* Important to override any default Vuetify text colors */
}

.navbar-text {
  color: var(--rich-earth-brown);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.25rem; /* Adjust size as needed */
  margin-left: 16px; /* Spacing between the logo and the text */
}

</style>
