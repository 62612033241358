// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyABRyeJYyBwVJE7ja_AVcRcNXyE2xG9tSM",
  authDomain: "fare-and-balanced-landing-page.firebaseapp.com",
  projectId: "fare-and-balanced-landing-page",
  storageBucket: "fare-and-balanced-landing-page.appspot.com",
  messagingSenderId: "911526582159",
  appId: "1:911526582159:web:82fefb5116069a7e895dfa",
  measurementId: "G-VMLMCKNQZ3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db }; // Correctly exported